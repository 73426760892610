import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import SignIn from './pages/SignIn/SignIn'
import Logout from './pages/Logout/Logout'
import ForgotPwd from './pages/ForgotPassword/ForgotPassword.styles.js'
import MainScreen from './pages/MainScreen/MainScreen'

import { isAuthenticated } from './services/auth'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
    }
  />
)

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path='/login' component={SignIn} />
      <Route exact path='/logout' component={Logout} />
      <Route exact path='/forgotpwd' component={ForgotPwd} />
      <PrivateRoute path='/' component={MainScreen} />
    </Switch>
  </BrowserRouter>
)

export default Routes
