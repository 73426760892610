import { Formik } from 'formik'
import SearchBenefitsForm from './SearchBenefitsForm'
import searchBenefitsFormValidator from './SearchBenefitsForm.validator'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SearchBenefitsFormWrapper extends Component {
  render () {
    return (
      <Formik
        initialValues={{ cpf: '' }}
        onSubmit={values => this.props.handleSubmit(values)}
        validationSchema={searchBenefitsFormValidator}
        render={props => (
          <SearchBenefitsForm {...props} isLoading={this.props.isLoading} />
        )}
      />
    )
  }
}

SearchBenefitsFormWrapper.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default SearchBenefitsFormWrapper
