import api from './api'

export const TOKEN_KEY = '@yalo-benefits-Token'

export const TOKEN_PORTALRH_KEY = '@portalRh-Token'

export const isAuthenticated = () => window.sessionStorage.getItem(TOKEN_KEY) !== null

export const getHeaderAuthentication = () => window.sessionStorage.getItem(TOKEN_KEY)

export const getHeaderAuthenticationPortalRh = () => window.sessionStorage.getItem(TOKEN_PORTALRH_KEY)

export const login = token => {
  window.sessionStorage.setItem(TOKEN_KEY, token)
}

export const logout = () => {
  window.sessionStorage.removeItem(TOKEN_KEY)
}

export const loginPortalRh = token => {
  window.sessionStorage.setItem(TOKEN_PORTALRH_KEY, `Bearer ${token}`)
}

export const logoutPortalRh = () => {
  window.sessionStorage.removeItem(TOKEN_PORTALRH_KEY)
}

export const preLogin = async (username) => {
  const body = {
    username: username
  }

  try {
    const response = await api.get(`/v1/users/${username}/pre_login`, body)

    return response.data
  } catch (err) {
    throw new Error('Ocorreu um erro')
  }
}
