import axios from 'axios'
import { getHeaderAuthentication, getHeaderAuthenticationPortalRh } from './auth'

let API_URL = process.env.API_URL
let API_PORTALRH_URL = process.env.API_URL_PORTALRH

export const api = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json' },
  responseType: 'json'
})

export const apiPortalRh = axios.create({
  baseURL: API_PORTALRH_URL,
  headers: { 'Content-Type': 'application/json' },
  responseType: 'json'
})

apiPortalRh.interceptors.request.use(async config => {
  const token = getHeaderAuthenticationPortalRh()
  if (token) {
    config.headers.Authorization = `${token}`
  } else {
    config.headers.Authorization = null
  }
  return config
})

api.interceptors.request.use(async config => {
  const token = getHeaderAuthentication()
  if (token) {
    config.headers.Authorization = `${token}`
  } else {
    config.headers.Authorization = null
  }
  return config
})

api.interceptors.response.use(function (response) {
  return response
}, function (error) {
  return Promise.reject(error)

  /* if (error.response.status === 401) {
  } else {
    return Promise.reject(error)
  } */
})

export default api
