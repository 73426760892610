import React from 'react'
import Container from '@material-ui/core/Container'
import { Button, Typography, withStyles } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import styles from './ChangePasswordForm.styles'

function ChangePasswordForm ({ classes, handleSubmit, handleCancel, errors, values, handleChange, handleBlur, isLoading, touched, formError, shouldChangePassword }) {
  return (
    <Container component='main' maxWidth='sm'>
      <div className={classes.paper}>
        <Typography component='h1' variant='h5' align='center' className={classes.headerTitle}>
          {shouldChangePassword
            ? 'Para a sua segurança, efetue a troca da senha de acesso temporária.'
            : 'Trocar senha de acesso ao Yalo Benefícios'}
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            name='oldPassword'
            variant='outlined'
            label='Senha Atual'
            value={values.oldPassword}
            error={errors.oldPassword && touched.oldPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            margin='normal'
            type='password'
            fullWidth
          />
          {errors.oldPassword && touched.oldPassword && <FormHelperText error>{errors.oldPassword}</FormHelperText>}
          <TextField
            name='newPassword'
            variant='outlined'
            label='Nova Senha'
            value={values.newPassword}
            error={errors.newPassword && touched.newPassword}
            margin='normal'
            onBlur={handleBlur}
            onChange={handleChange}
            type='password'
            fullWidth
          />
          {errors.newPassword && touched.newPassword && <FormHelperText error>{errors.newPassword}</FormHelperText>}
          <TextField
            name='passwordConfirmation'
            variant='outlined'
            label='Confirmar Senha'
            value={values.passwordConfirmation}
            error={errors.passwordConfirmation && touched.passwordConfirmation}
            margin='normal'
            onBlur={handleBlur}
            onChange={handleChange}
            type='password'
            fullWidth
          />
          {errors.passwordConfirmation && touched.passwordConfirmation && <FormHelperText error>{errors.passwordConfirmation}</FormHelperText>}
          {formError && <FormHelperText className={classes.error} error>{formError}</FormHelperText>}
          <div className={classes.buttons}>
            {!shouldChangePassword && <Button
              variant='contained'
              className='greyPrimary'
              onClick={handleCancel}>
              Cancelar
            </Button> }
            <Button
              color='primary'
              variant='contained'
              type='submit'
            > Ok </Button>
          </div>
        </form>
      </div>
    </Container>
  )
}

/* ChangePasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
} */

export default withStyles(styles)(ChangePasswordForm)
