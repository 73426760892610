import { required, invalidCPF } from '../../../utils/ValidationMessages.json'
import { validateCpf } from '../../../utils/ValidationFunctions'

import * as Yup from 'yup'

const fields = {
  cpf: Yup
    .string()
    .test('cpf', invalidCPF, (cpf) => {
      if (cpf && cpf.length === 14) {
        return validateCpf(cpf)
      }
      return true
    })
    .required(required)
}

export default Yup.object().shape(fields)
