import api from './api'

export const changePassword = async (oldPassword, newPassword) => {
  const body = {
    oldPassword,
    newPassword
  }

  try {
    const response = await api.put(`/v1/admin/change_password`, body)

    return response.data
  } catch (err) {
    if (err.response.status === 401) {
      throw new Error('Senha atual incorreta')
    } else {
      throw new Error('Ocorreu um erro, tentei novamente')
    }
  }
}
