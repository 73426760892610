import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import InputMask from 'react-input-mask'
import classes from './SearchBenefitsForm.module.css'

const SearchBenefitsForm = props => {
  const {
    handleSubmit,
    errors,
    values,
    handleChange,
    isLoading
  } = props

  return (
    <div className={classes.search}>
      <form id='searchBenefits' onSubmit={handleSubmit}>
        <InputMask
          mask={'999.999.999-99'}
          maskChar={null}
          value={values.cpf}
          onChange={handleChange}
        >
          {() => {
            return (
              <TextField
                id='cpf'
                name='cpf'
                label='CPF'
                className={classes.textField}
                variant='outlined'
                margin='none'
                InputProps={{ style: { color: 'grey', fontSize: '1.5rem' } }}
                error={Boolean(errors.cpf)}
                helperText={errors.cpf}
              />
            )
          }}
        </InputMask>
        <Button
          type='submit'
          form='searchBenefits'
          variant='contained'
          color='primary'
          disabled={values.cpf.length !== 14 || Boolean(errors.cpf) || isLoading}
          className={classes.searchButton}>
          {isLoading ? <CircularProgress /> : 'Buscar'}
        </Button>
      </form>

    </div>
  )
}

SearchBenefitsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default SearchBenefitsForm
