import { Formik } from 'formik'
import ForgotPasswordForm from './ForgotPasswordForm'
import ForgotPasswordFormValidator from './ForgotPasswordForm.validator'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ForgotPasswordFormWrapper extends Component {
  render () {
    return (
      <Formik
        initialValues={{ email: '' }}
        onSubmit={values => this.props.handleSubmit(values)}
        validationSchema={ForgotPasswordFormValidator}
        render={props => (
          <ForgotPasswordForm {...props} isLoading={this.props.isLoading} />
        )}
      />
    )
  }
}

ForgotPasswordFormWrapper.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default ForgotPasswordFormWrapper
