const styles = theme => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  imgContainer: {
    width: '100%',
    marginBottom: '8px'
  },
  imgWrapper: {
    width: '150px',
    margin: '0 auto'
  },
  img: {
    maxWidth: '100%'
  },
  paper: {
    padding: '30px',
    width: '100%'
  },
  formContainer: {
    marginBottom: '8px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  background: {
    height: '100vh',
    width: '100%',
    backgroundColor: theme.palette.background.default
  },
  button: {
    width: '100px'
  }
})

export default styles
