import api from './api'

export const forgotPassword = async (email) => {
  const body = {
    username: email,
    origin: 'benefits'
  }

  try {
    const response = await api.post(`/v1/forgot_password`, body)

    return response.data
  } catch (err) {
    throw new Error('Ocorreu um erro, tente novamente')
  }
}
