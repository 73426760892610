const styles = theme => ({
  changePasswordForm: {

  },
  headerTitle: {
    color: 'grey'
  },
  textField: {
    width: '100%'
  },
  paper: {
    marginTop: '70px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: '20px',
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '20px'
  },
  submit: {
    margin: '20px'
  },
  buttons: {
    display: 'flex',
    marginTop: '16px',
    justifyContent: 'flex-end',
    '& button': {
      width: '100px',
      marginLeft: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& button': {
        width: '100%',
        margin: '10px'
      }
    }
  },
  error: {
    fontSize: '20px',
    textAlign: 'center'
  }
})

export default styles
