import { required, passwordMatch, passwordRule } from '../../utils/ValidationMessages.json'
import * as Yup from 'yup'

const fields = {
  oldPassword: Yup
    .string()
    .matches(/^(?=.*\d)(?=.*[A-Za-z])[\x21-\x7E]{6,}$/, passwordRule)
    .required(required),
  newPassword: Yup
    .string()
    .matches(/^(?=.*\d)(?=.*[A-Za-z])[\x21-\x7E]{6,}$/, passwordRule)
    .required(required),
  passwordConfirmation: Yup
    .string()
    .oneOf([Yup.ref('newPassword')], passwordMatch)
    .required(required)
}

export default Yup.object().shape(fields)
