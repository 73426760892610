import React, { Component } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core/';
import { integration } from '../../services/integration';
import { getProfile } from '../../services/getProfile.js';
import yaloImages from '../../assets/images';
import classes from './SearchBenefits.module.css';
import { getBenefits } from '../../services/benefits';
import CheckBoxIcon from '@material-ui/icons/CheckCircle';
import Container from '@material-ui/core/Container';
import SearchBenefitsForm from './SearchBenefitsForm/SearchBenefitsForm.wrapper';
import { toast } from 'react-toastify';
import moment from 'moment';
import { filter } from 'lodash';
class SearchBenefits extends Component {
  constructor() {
    super();
    this.state = {
      cpf: '',
      isLoading: false,
      redirect: false,
      error: '',
      plans: [],
      open: false,
      openConfirmation: false,
      isIntegrating: false,
      message: '',
      profile: null,
      accountId:null,
    };
  }

  render() {
    const { isLoading, open, message } = this.state;
    return (
      <Container component='main' maxWidth='sm' className={classes.container}>
        <div className={classes.logo}>
          <img src={yaloImages.icone_yalo} title='YALO' alt='YALO benefícios' />
        </div>
        <SearchBenefitsForm
          isLoading={isLoading}
          handleSubmit={this.handleSubmit}
        />
        <div className={classes.benefits}>{this.renderBenefits()}</div>
        <Dialog open={open} onClose={this.handleClose}>
          {message}
          <DialogActions>
            <Button
              onClick={() => this.setState({ open: false })}
              color='primary'
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        {this.renderConfirmationMessage()}
      </Container>
    );
  }

  handleClose = () => {
    this.setState({
      open: false,
      openConfirmation: false,
      isLoading: false,
      isIntegrating: false,
      message: '',
    });
  };

  handleChange = (event) => {
    this.setState({ cpf: event.target.value });
  };

  handleSubmit = async (formValues) => {
    const { history } = this.props;
    const cpf = formValues.cpf.replace(/\D/g, '');

    this.setState({ isLoading: true });

    try {
      const response = await getBenefits(cpf, history);

      this.setState({
        plans: response.data.plans,
        hasBenefits: response.data.plans && response.data.plans.length > 0,
      });
    } catch (err) {
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      this.setState({ isLoading: false });
      return;
    }

    try {
      const respProfile = await getProfile(cpf);

      this.setState({
        isLoading: false,
        profile: respProfile.data,
      });
    } catch (error) {
      toast.error('O CPF informado não foi encontrado.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      this.setState({ isLoading: false });
    }
  };

  handleIntegration = async () => {
    const { history } = this.props;
    const { profile, accountId } = this.state;
    this.setState({  isIntegrating: true });

    try {
      
      const payload = {
        partner_integration_id: 'fd307751-4aa2-f945-da42-944cf9ffc157',
        account_id: accountId,
        person_id: profile.id,
        integration_date: moment().format('YYYY-MM-DD'),
        card_id: null,
        order_id: null,
        payload: null,
        channel: 'integração-beneficios',
      };

      await integration(payload, history);

      this.setState({
        open: true,
        isIntegrating: false,
        message: this.renderSuccessMessage(),
      });
    } catch (error) {
      console.error(JSON.stringify(error));
      
      if (error.message) {
        toast.error(error.response.data.message[0], {
          position: toast.POSITION.BOTTOM_RIGHT,
        });

        this.setState({ isIntegrating: false });
      } else
        this.setState({
          open: true,
          isIntegrating: false,
          message: this.renderFailMessage(),
        });
    }
    this.setState({openConfirmation: false})
  };

  renderSuccessMessage = () => (
    <DialogContent>
      <Grid container justify='center' alignItems='center'>
        <Typography paragraph variant='h1' color='primary'>
          Ativação enviada com sucesso!
        </Typography>
        <Typography paragraph variant='h3'>
          Por favor reinicie o atendimento do paciente e o CPF deve constar como
          ativo normalmente no S2
        </Typography>
      </Grid>
    </DialogContent>
  );

  renderFailMessage = () => (
    <DialogContent>
      <Grid container justify='center' alignItems='center'>
        <Typography paragraph variant='h1'>
          Continua com problema?
        </Typography>
        <Typography paragraph variant='h3'>
          Hmmm, isso pode ser um problema maior! Por favor, oriente o paciente a
          entrar em contato com a Yalo pelo: 0800 591 0697.
        </Typography>
      </Grid>
    </DialogContent>
  );

  renderConfirmationMessage = () => {
    const { openConfirmation,isIntegrating } = this.state;
    return (
      <Dialog open={openConfirmation} >
        <DialogContent>
          <Grid container justify='center' alignItems='center'>
            <Typography paragraph variant='h1'>
              Já conferiu o CPF do paciente?
            </Typography>
            <Typography paragraph variant='h3'>
              O CPF do paciente deve estar preenchido corretamente no sistema s2
              e este deve ser o mesmo registrado no seu cadastro YALO.
              Certifique-se de que o paciente a ser atendido é o paciente que
              possui assinatura YALO ativa.
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()}>Cancelar</Button>
          <Button 
            onClick={() => this.handleIntegration()} 
            color='primary'
            variant='contained'
            size='small'
            gutterBottom
            disabled={isIntegrating}
          >
            {isIntegrating ? <CircularProgress size={23} /> : 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderIntegrationButton = (plan) => {
    const { profile } = this.state;
    const data = JSON.parse(window.sessionStorage.getItem('data'));
    
    let isDrc =
      filter(
        data.partners,
        (partner) => partner.id === '6f6d36d9-cb7b-4d66-9d16-21d34298aa9d'
      ).length > 0;
      
    const account = profile && profile.accounts.find((acc) => acc.accountPlan.plan.name === plan.name);
    
    if (isDrc) {
      return (
        <Button
          onClick={() => this.setState({ openConfirmation: true, accountId:account.id })}
          color='primary'
          variant='contained'
          size='small'
          gutterBottom
        >
        Integrar
        </Button>
      );
    }
  };

  renderBenefits = () => {
    const { plans, hasBenefits, isLoading } = this.state;
  
    if (!isLoading && typeof hasBenefits !== 'undefined') {
      let content = null;

      if (hasBenefits === true) {
        content = (
          <React.Fragment>
            <Typography gutterBottom variant='h1'>
              Assinaturas e benefícios
            </Typography>
            {plans.map((plan) => {
              return (
                <div key={plan.name} className={classes.benefitCard}>
                  <Grid
                    container
                    alignItems='center'
                    className={classes.benefitCardTitle}
                  >
                    <CheckBoxIcon
                      color='primary'
                      style={{ marginRight: '10px' }}
                    />
                    <Typography variant='h2'> {plan.name} </Typography>
                  </Grid>

                  {plan.benefits.map((benefit) => {
                    return (
                      <Typography key={benefit.name}>
                        {' '}
                        Benefício: {benefit.name}{' '}
                      </Typography>
                    );
                  })}
                  {this.renderIntegrationButton(plan)}
                </div>
              );
            })}
          </React.Fragment>
        );
      } else {
        content = (
          <Typography variant='h2' align='center'>
            Nenhum benefício encontrado :(
          </Typography>
        );
      }

      return content;
    }
    return null;
  };
}

export default SearchBenefits;
