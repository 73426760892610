import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import MaterialAppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Menu from '@material-ui/core/Menu'

import { Link } from 'react-router-dom'
import yaloImages from '../../assets/images'

const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menu: {

  }
}

function AppBar (props) {
  const { classes } = props

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleMenu (event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose () {
    setAnchorEl(null)
  }

  function handleChangePassword () {
    handleClose()
    props.history.push('/senha')
  }

  function handleLogout () {
    handleClose()
    props.history.push('/logout')
  }

  function handleHome () {
    handleClose()
    props.history.push('/')
  }

  return (
    <div className={classes.root} >
      <MaterialAppBar position='static' style={{ boxShadow: 'none' }}>
        <Toolbar>
          <div className={classes.grow}>
            <Link to='/' title='Yalo' >
              <img
                width='100'
                src={yaloImages.icone_yalo}
                alt='YALO'
                title='YALO'
              />
            </Link>
          </div>
          <div>
            <IconButton
              onClick={handleMenu}
              style={{ color: 'white' }}
              size='medium'
            >
              <AccountCircle fontSize='large' />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleHome}>Início</MenuItem>
              <MenuItem onClick={handleChangePassword}>Trocar senha</MenuItem>
              <MenuItem onClick={handleLogout}>Sair</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </MaterialAppBar>
    </div>
  )
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(AppBar)
