import ForgotPassword from './ForgotPassword'
import { withStyles } from '@material-ui/core'

const styles = theme => {
  return ({
    container: {
      height: '100%',
      display: 'flex',
      alignItems: 'center'
    },
    imgContainer: {
      width: '100%',
      marginBottom: '8px'
    },
    imgWrapper: {
      width: '150px',
      margin: '0 auto'
    },
    img: {
      maxWidth: '100%'
    },
    paper: {
      padding: '30px',
      width: '100%'
    },
    formContainer: {
      marginBottom: '8px'
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    background: {
      height: '100vh',
      width: '100%',
      backgroundColor: theme.palette.background.default
    }
  })
}

export default withStyles(styles)(ForgotPassword)
