export default {
  palette: {
    primary: {
      light: '#5ace50',
      main: '#51b848',
      dark: '#46a23e'
    },
    secondary: {
      light: '#636F86',
      main: '#384459',
      dark: '#101D30'
    },
    error: {
      main: '#EC213F'
    },
    background: {
      default: '#fafafa'
    }
  },
  status: {
    success: '#64C160',
    warning: '#F2994A',
    error: '#EC213F'
  },

  typography: {
    useNextVariants: true,
    fontFamily: [
      'Muli',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      fontWeight: 500,
      fontSize: 24,
      color: 'rgba(56, 68, 89, 0.87)',
      lineHeight: 1.75
    },
    h2: {
      fontWeight: 400,
      fontSize: 22,
      color: 'rgba(56, 68, 89, 0.87)',
      lineHeight: 1.75
    },
    h3: {
      fontWeight: 400,
      fontSize: 18,
      color: 'rgba(56, 68, 89, 0.87)',
      lineHeight: 1.75
    },
    h4: {
      fontWeight: 400,
      fontSize: 16,
      color: 'rgba(56, 68, 89, 0.87)',
      lineHeight: 1.75
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 14,
      color: 'rgba(56, 68, 89, 0.6)',
      lineHeight: 1.75
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 14,
      color: 'rgba(56, 68, 89, 0.87)',
      lineHeight: 1.75
    },
    body1: {
      fontWeight: 400,
      fontSize: 14,
      color: 'rgba(56, 68, 89, 0.87)',
      lineHeight: 1.75
    },
    body2: {
      fontWeight: 700,
      fontSize: 14,
      color: 'rgba(56, 68, 89, 0.87)',
      lineHeight: 1.75
    },
    button: {
      fontWeight: 600
    }
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&.greyPrimary': {
          color: 'rgba(0, 0, 0, 0.26)'
        }
      },
      containedPrimary: {
        color: 'white'
      }
    }
  }
}
