import React from 'react'
import { Typography, TextField, Button, withStyles, CircularProgress } from '@material-ui/core'
import { Link } from 'react-router-dom'
import styles from './ForgotPasswordForm.styles.js'

const ForgotPassword = props => {
  const {
    handleSubmit,
    errors,
    values,
    handleChange,
    isLoading,
    isValid,
    classes
  } = props

  return (
    <form id='forgotPasswordForm' onSubmit={handleSubmit}>
      <div className={classes.formContainer}>
        <Typography align='center' variant='h3'>
          Yalo benefícios - Recuperação de senha
        </Typography>
        <TextField
          required
          variant='outlined'
          name='email'
          value={values.email}
          label='Email de acesso'
          margin='normal'
          fullWidth
          onChange={handleChange}
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
      </div>
      <div className={classes.actions}>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          disabled={!isValid || isLoading}
          className={classes.button}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Enviar'}
        </Button>
        <Link style={{ color: '#4db848' }} to='/signin'>
          Voltar ao login
        </Link>
      </div>
    </form>
  )
}

export default withStyles(styles)(ForgotPassword)
