import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import SearchBenefits from '../SearchBenefits/SearchBenefits'
import classes from './MainScreen.module.css'
import AppBar from '../../components/AppBar/AppBar'
import ChangePasswordForm from '../ChangePassword/ChangePasswordForm.wrapper'

class MainScreen extends Component {
  constructor (props) {
    super(props)
    this.state = {
      profile: {},
      loading: false
    }
  }

  render () {
    return (
      <div className={classes.mainScreen}>
        <AppBar history={this.props.history} />
        <Switch>
          <Route exact path='/' component={SearchBenefits} />
          <Route path='/senha' component={() => <ChangePasswordForm history={this.props.history} />} />
          <Route component={() => <h1>Page not found</h1>} />
        </Switch>
      </div>
    )
  }
}

export default MainScreen
