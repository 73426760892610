import { Formik } from 'formik'
import React, { Component } from 'react'
/* import PropTypes from 'prop-types' */
import ChangePasswordFormValidator from './ChangePasswordForm.validator'
import ChangePasswordForm from './ChangePasswordForm'
import { changePassword } from '../../services/changePassword'
import { toast } from 'react-toastify'
import _ from 'lodash'

class ChangePasswordFormWrapper extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: ''
    }
  }

  initialValues = {
    oldPassword: '',
    newPassword: '',
    passwordConfirmation: ''
  }

  render () {
    const { history } = this.props
    const shouldChangePassword = _.get(history, 'location.state.shouldChangePassword')

    return (
      <Formik
        initialValues={this.initialValues}
        onSubmit={this.handleSubmit}
        validationSchema={ChangePasswordFormValidator}
        render={props => (
          <ChangePasswordForm
            values={props.values}
            errors={props.errors}
            isLoading={this.props.changePasswordIsLoading}
            handleChange={props.handleChange}
            handleCancel={this.handleCancel}
            handleSubmit={props.handleSubmit}
            handleBlur={props.handleBlur}
            touched={props.touched}
            formError={this.state.error}
            shouldChangePassword={shouldChangePassword}
          />
        )} />
    )
  }

  handleSubmit = async ({ oldPassword, newPassword }, actions) => {
    const { history } = this.props

    try {
      await changePassword(oldPassword, newPassword)
      toast.success('Senha trocada com sucesso!', {
        position: toast.POSITION.BOTTOM_RIGHT
      })
      history.push('/')
    } catch (error) {
      toast.error('Ocorreu um erro :/', {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }

  handleCancel = () => {
    const { history } = this.props
    history.push('/')
  }
}

/* ChangePasswordFormWrapper.propTypes = {
  history: PropTypes.object.isRequired,
  birthDate: PropTypes.string.isRequired,
  changePassword: PropTypes.func.isRequired,
  changePasswordIsLoading: PropTypes.bool.isRequired,
  openRequestModal: PropTypes.func.isRequired
} */

export default ChangePasswordFormWrapper
