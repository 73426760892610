import React from 'react'
import './App.css'
import Routes from './routes'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import mainTheme from './mainTheme'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App () {
  const applyTheme = createMuiTheme(mainTheme)
  toast.configure()

  return (
    <MuiThemeProvider theme={applyTheme}>
      <Routes />
    </MuiThemeProvider>

  )
}

export default App
