import { required, email } from '../../../utils/ValidationMessages.json'

import * as Yup from 'yup'

const fields = {
  email: Yup
    .string()
    .required(required)
    .email(email)
}

export default Yup.object().shape(fields)
