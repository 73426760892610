import React, { Component } from 'react'
import { Container, Paper } from '@material-ui/core'
import yaloImages from '../../assets/images'
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm.wrapper.js'
import { forgotPassword } from '../../services/forgotPassword'
import { toast } from 'react-toastify'

class ForgotPassword extends Component {
  constructor () {
    super()
    this.state = {
      isLoading: false
    }
  }
  render () {
    const { classes } = this.props
    return (
      <div className={classes.background}>
        <Container className={classes.container} maxWidth='sm' fixed='true'>
          <Paper className={classes.paper}>
            <div className={classes.imgContainer}>
              <div className={classes.imgWrapper}>
                <img
                  src={yaloImages.logo_yalo} alt='YALO' title='YALO' className={classes.img}
                />
              </div>
            </div>
            <ForgotPasswordForm handleSubmit={this.handleSubmit} isLoading={this.state.isLoading} />
          </Paper>
        </Container>
      </div>
    )
  }

  handleSubmit = async (values) => {
    const { history } = this.props

    this.setState({ isLoading: true })

    try {
      const response = await forgotPassword(values.email)

      this.setState({
        isLoading: false
      })

      if (response.masked_email) {
        toast.success('Uma nova senha de acesso foi enviada. Confira seu email!', {
          position: toast.POSITION.BOTTOM_RIGHT
        })
        history.push('/login')
      } else {
        toast.error('Desculpe, mas não encontramos o email informado.', {
          position: toast.POSITION.BOTTOM_RIGHT
        })
      }
    } catch (err) {
      this.setState({ isLoading: false })
      toast.error(err.message, {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  }
}

export default ForgotPassword
