import PropTypes from 'prop-types'
import { logout } from '../../services/auth'

function Logout (props) {
  const { history } = props

  logout()

  history.push('/login')

  return null
}

Logout.propTypes = {
  history: PropTypes.object.isRequired
}

export default Logout
